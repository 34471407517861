import React, {RefObject} from "react";
import {AppContext, AppContextType} from "../AppContext";
import firebase from "../firebase";
import './Home.css';
import {DialogPropTypes} from "../ui/dialog/DialogContext";
import WithDialog from "../ui/dialog/WithDialog";
import {withNavigate} from "../WithNavigate";
import {ImagesSlider} from "../ui/images-slider/ImagesSlider";
import SoftwareCell from "../software/software-cell/Software-Cell";
import {goTo} from "../Utils";
import NewsCell from "../news/news-cell/News-Cell";
import ScrollContainer from "react-indiana-drag-scroll";
import SoftwareSecondaryCell from "../software/software-cell/Software-Secondary-Cell";
// @ts-ignore
import {Helmet} from "react-helmet";

interface HomeProp extends DialogPropTypes {
    navigate: any;
}

class Home extends React.Component<HomeProp, any> {
    swiper: any;

    homeMedia: RefObject<any>;
    partner: RefObject<any>;

    constructor(props: any) {
        super(props);
        this.homeMedia = React.createRef();
        this.partner = React.createRef();
        this.swiper = React.createRef();
        this.state = {}
    }


    async componentDidMount() {
        const ctx = (this.context as AppContextType).appContext;

        const dbRef = firebase.database().ref(ctx.basePath + '/pagine/home');
        dbRef.on('value', snapshot => {
            this.setState({home: snapshot.val()})
        });


    }


    render() {

        window.onscroll = () => {

                       if (this.homeMedia && this.homeMedia.current && (window.pageYOffset/100)<4.5) {
                           this.homeMedia.current.style.transform = "scale(1)";
                       }
                       if (this.homeMedia && this.homeMedia.current && (window.pageYOffset/100)>4.5 && (window.pageYOffset/100)<10) {
                           this.homeMedia.current.style.transform = "scale(1.2)";
                           this.homeMedia.current.class = 'pippo'
                       }
                       if (this.homeMedia && this.homeMedia.current && (window.pageYOffset/100)>10) {
                           this.homeMedia.current.style.transform = "scale(1)";
                       }

/*
            if (this.partner && this.partner.current && window.pageYOffset < 600) {
                this.partner.current.style.opacity = 1
            }
            if (this.partner && this.partner.current && window.pageYOffset > 600) {
                this.partner.current.style.opacity = 1 - (1 - Math.round((1 / (window.pageYOffset) * 100) * 10) / 10)
                            }

            if (this.partner && this.partner.current && window.pageYOffset < 700) {
                this.partner.current.style.display = 'initial'
            }
            if (this.partner && this.partner.current && window.pageYOffset > 700) {
                this.partner.current.style.display = 'none'
            }
            */

        };


        return <div id={'home-page'}>

            {/* SEO */}
            <Helmet>
                {this.state?.home?.seo?.meta_titolo?._value ?
                    <title>{this.state?.home?.seo?.meta_titolo?._value}</title> :
                    <title>{process.env.REACT_APP_TITLE}</title>}
                <meta name='description' content={this.state?.home?.seo?.meta_descrizione?._value} />
                <meta name='keywords' content={this.state?.home?.seo?.meta_keywords?._value} />
            </Helmet>

            {/*Intro*/}
            <div data-aos="fade-in" data-aos-once="true" className={'intro-div padded'}>

                <p className={'intro-test-p font-48-600'}
                   dangerouslySetInnerHTML={{__html: this.state.home?.introduzione?.intro_testo?._value}}>

                </p>

                {
                    this.state.home?.introduzione?.intro_link?.etichetta?._value?
                        <div className={'btn btn-background-color'}
                             onClick={(event) => {
                                 goTo(this.state.home?.introduzione?.intro_link?.link?._value, event)
                             }}>
                            {this.state.home?.introduzione?.intro_link?.etichetta?._value}

                        </div> : null
                }


            </div>

            <div className={'partner-fixed'} ref={this.partner}></div>

            {/*Image*/}
            <div id={'home-media-container'}>
                <div id={'home-media'} ref={this.homeMedia} data-aos="fade-up" data-aos-once="true"
                     className={'img-container-home-div img-container-main-home-div mini-padded kreosoft-pointer'}>
                    {
                        this.state.home?.copertina?._list && Object.keys(this.state.home?.copertina?._list).length > 0 ?
                            <ImagesSlider autoplay={true} images={this.state.home?.copertina?._list}>
                            </ImagesSlider> : ''
                    }
                </div>
            </div>


            {/*Software*/}
            <div className={'software-div padded'}>

                <p className={'title-p font-40-600 accent-text'}>
                    {this.state.home?.software?.titolo?._value}
                </p>

                <p className={'subtitle-p font-14 accent-text'}
                   dangerouslySetInnerHTML={{__html: this.state.home?.software?.descrizione?._value}}>
                </p>

                <div className={'software-list-container-div'}>
                    {
                        this.state.home?.software?.elenco_software?._list &&
                        Object.keys(this.state.home?.software?.elenco_software?._list).length > 1 ?
                            Object.keys(this.state.home?.software?.elenco_software?._list)
                                .filter(s => s != "0")
                                .sort((key1, key2)=> this.state.home?.software?.elenco_software?._list[key1]?._order > this.state.home?.software?.elenco_software?._list[key2]?.order? -1:1)
                                .map((s, i) =>
                                <SoftwareCell index={i}
                                              key={'s-h-' + i}
                                              softwareKey={this.state.home?.software?.elenco_software?._list[s]._linkedId}>

                                </SoftwareCell>
                            )
                            : ""
                    }
                </div>

                <div className={'btn-software-container-div'}>

                    <div className={'btn btn-background-color'}
                         onClick={(event) => {
                             goTo(this.state.home?.software?.software_link?.link?._value, event)
                         }}>
                        {this.state.home?.software?.software_link?.etichetta?._value}
                    </div>

                </div>

            </div>

            {/*Software secondari*/}
            <div className={'software-div padded'}>

                <p className={'title-p font-40-600 accent-text'}>
                    {this.state.home?.software_secondari?.titolo?._value}
                </p>

                <p className={'subtitle-p font-14 accent-text'}
                   dangerouslySetInnerHTML={{__html: this.state.home?.software_secondari?.descrizione?._value}}>
                </p>

                <div className={'software-list-container-div software-list-secondary-container-div'}>
                    {
                        this.state.home?.software_secondari?.elenco_software?._list &&
                        Object.keys(this.state.home?.software_secondari?.elenco_software?._list).length > 1 ?
                            Object.keys(this.state.home?.software_secondari?.elenco_software?._list)
                                .filter(s => s != "0")
                                .sort((key1, key2)=> this.state.home?.software_secondari?.elenco_software?._list[key1]?._order > this.state.home?.software_secondari?.elenco_software?._list[key2]?.order? -1:1)
                                .map((s, i) =>
                                    <SoftwareSecondaryCell index={i}
                                                  key={'s-h-' + i}
                                                  softwareKey={this.state.home?.software_secondari?.elenco_software?._list[s]._linkedId}>

                                    </SoftwareSecondaryCell>
                                )
                            : ""
                    }
                </div>

                <div className={'btn-software-container-div'}>
                    {this.state.home?.software_secondari?.software_link?.etichetta?._value?
                        <div className={'btn btn-background-color'}
                             onClick={(event) => {
                                 goTo(this.state.home?.software_secondari?.software_link?.link?._value, event)
                             }}>
                            {this.state.home?.software_secondari?.software_link?.etichetta?._value}
                        </div>
                        : null
                    }


                </div>

            </div>

            {/*News*/}
            <div className={'news-div padded'}>

                <p className={'title-p font-40-600 accent-text'}>
                    {this.state.home?.news?.titolo?._value}
                </p>

                <p className={'subtitle-p font-14 accent-text'}
                   dangerouslySetInnerHTML={{__html: this.state.home?.news?.descrizione?._value}}>
                </p>

                <div className={'software-list-container-div'}>
                    {
                        this.state.home?.news?.elenco_news?._list &&
                        Object.keys(this.state.home?.news?.elenco_news?._list).length > 1 ?
                            Object.keys(this.state.home?.news?.elenco_news?._list)
                                .filter(s => s != "0")
                                .sort((key1, key2)=> this.state.home?.news?.elenco_news?._list[key1]?._order > this.state.home?.news?.elenco_news?._list[key2]?.order? -1:1)
                                .map((s, i) =>
                                <NewsCell
                                    index={i}
                                    key={'n-h-' + i}
                                    newsKey={this.state.home?.news?.elenco_news?._list[s]._linkedId}>

                                </NewsCell>
                            )
                            : ""
                    }
                </div>

                <div className={'btn-news-container-div'}>

                    {this.state.home?.news?.news_link?.link?._value?
                        <div className={'btn btn-background-color'}
                             onClick={(event) => {
                                 goTo(this.state.home?.news?.news_link?.link?._value, event)
                             }}>
                            {this.state.home?.news?.news_link?.etichetta?._value}
                        </div>
                        :null
                    }


                </div>

            </div>

            {/* Gallery immagini */}
            <div id={'home-gallery-2'} className="gallery-container">

                <ScrollContainer className="gallery">
                    {
                        Object.keys(this.state.home?.gallery_fine_pagina?._list||{})?.
                        filter((key:string)=> key!="0")?.
                        map((key:string)=>this.state.home?.gallery_fine_pagina?._list[key])?.sort((item1, item2)=> item1?._order > item2?.order? -1:1).
                        map((immagine:any, index:number) => {
                            return <img data-aos="fade-in" data-aos-once="true" data-aos-delay={50*(index??0)} key={'phco'+ index} className="gallery-item background" src={immagine?.immagine?._value} ></img>
                        })
                    }

                </ScrollContainer>
            </div>




        </div>

    }

}

Home.contextType = AppContext;
export default withNavigate(WithDialog(Home));

