import React from "react";
import './ListItem.css';
import {DialogPropTypes} from "../ui/dialog/DialogContext";
import {AppContext, AppContextType} from "../AppContext";
import {withNavigate} from "../WithNavigate";
import WithDialog from "../ui/dialog/WithDialog";


interface ListItemProps extends DialogPropTypes {
    title: string;
    description: string;
    index?: number;
    gallery: any[];
}

class ListItem extends React.Component<ListItemProps, any> {

    constructor(props: any) {
        super(props);
        this.state = {}
    }


    async componentDidMount() {
    }

    toggleCell() {
        this.setState((prevState: any) => ({
            show: !prevState?.show,
        }));
    }


    render() {
        return <div className={"list-item-cell"} data-aos="fade-in" data-aos-once="true"
                    data-aos-delay={50* (this.props?.index ?? 0)}>
            <div onClick={this.toggleCell.bind(this)} className={"list-item-title-container"}>

                <p dangerouslySetInnerHTML={{__html: this.props.title}} className={'font-24-600'}></p>
                <i
                    className={`font-24-600 icon-crea ${
                        this.state.show ? "rotated" : ""
                    }`}
                ></i>
            </div>

            <div
                className={
                    "list-item-description font-14 " +
                    (this.state.show ? "list-item-opened" : "list-item-closed")
                }
            >

                <div
                    dangerouslySetInnerHTML={{
                        __html: this.props.description,
                    }}
                ></div>

                {
                    this.props?.gallery ?
                        <div className={'logos-container'}>
                            {Object.keys(this.props?.gallery).filter((key: string) => key != '0').map((key: any) => this.props?.gallery[key]).map(item => {
                                return <img src={item?.immagine?._value} title={item?.alt?._value}/>;
                            })}
                        </div>
                        :
                        null
                }
            </div>
        </div>

    }

}

ListItem.contextType = AppContext;
export default withNavigate(WithDialog(ListItem));

