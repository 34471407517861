import React from "react";
import './PreviewBar.css';
import {AppContext, AppContextType} from "../AppContext";

class PreviewBarProps {
}
class PreviewBarState {
}


export class PreviewBar extends React.Component<PreviewBarProps, PreviewBarState> {



    constructor(props: any) {
        super(props);

    }

    async componentDidMount() {

    }


    render() {
        return <>
            { (this.context as AppContextType)?.appContext?.draft?
                <>
                    <div className={'preview-bar'}>
                        <div className={'preview-label'}>ANTEPRIMA</div>
                    </div>

                </>
                :null
            }

        </>
    }
}
PreviewBar.contextType = AppContext;

