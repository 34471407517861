import * as React from 'react';
import {AppContext, AppContextType} from "./AppContext";
import {BrowserRouter, HashRouter, Route, Routes} from "react-router-dom";
import ScrollToTop from './scroll-to-top/ScrollToTop';
import Home from "./home/Home";
import {Footer} from "./footer/Footer";
import {Toolbar} from "./toolbar/Toolbar";

import SoftwareList from "./software/software-list/Software-List";
import NewsList from "./news/news-list/News-List";
import SoftwareDetail from "./software/software-detail/Software-Detail";
import NewsDetail from "./news/news-detail/News-Detail";
import About from "./about/About";
import Contact from "./contact/Contact";
import CustomerService from "./customer-service/CustomerService";
import {PreviewBar} from "./preview-bar/PreviewBar";
import { HelmetProvider } from "react-helmet-async";



const Main = () => {

    const { appContext } = React.useContext(AppContext) as AppContextType;


    return (
        <>
            <HelmetProvider>
            <BrowserRouter>
            <PreviewBar></PreviewBar>

            <div>
                <Toolbar></Toolbar>

                <ScrollToTop />
                
                <Routes>
                    <Route path='*' element={<Home />} />
                    <Route path="/" element={<Home />} />


                    <Route  path="/prodotti" element={<SoftwareList />} />
                    <Route path="/prodotti/tag/:key" element={<SoftwareList />}  />
                    <Route  path="/about" element={<About />} />
                    <Route  path="/news" element={<NewsList />} />
                    <Route  path="/assistenza" element={<CustomerService />} />

                    <Route path="/prodotti/:key" element={<SoftwareDetail />}  />

                    <Route path="/news/:key" element={<NewsDetail />} />

                    <Route path="/contatti" element={<Contact />} />


                </Routes>


                <Footer />

            </div>
            </BrowserRouter>
                </HelmetProvider>
        </>

    );
};



export default Main
