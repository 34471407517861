import React from "react";
import './BackBar.css';
import {DialogPropTypes} from "../../ui/dialog/DialogContext";
import {AppContext, AppContextType} from "../../AppContext";
import {withNavigate} from "../../WithNavigate";
import WithDialog from "../../ui/dialog/WithDialog";

interface BackBarProp extends DialogPropTypes {
    navigate: any;
    params: any;
    key: any;
    buttons: any[];
}

class BackBar extends React.Component<BackBarProp, any> {

    constructor(props: any) {
        super(props);

        this.state = {}
    }


    async componentDidMount() {
        const ctx = (this.context as AppContextType).appContext;
console.log(this.props?.buttons)

    }

    goBack() {
        this.props.navigate(-1)
    }

    render() {

        return <div className={'back-bar-div padded'}>

            <div className={'back-btn'} onClick={() => this.goBack()}>
                <i className={'icon icon-indietro'}></i>
                <span className={'font-14'}>Torna indietro</span>
            </div>
            <div className={'back-bar-buttons-container'}>
                {
                    this.props && this.props?.buttons && this.props?.buttons?.length > 1 ?
                        this.props.buttons
                            .filter((s: any) => s?._id != 0)
                            .sort((a: any, b: any) => this.props?.buttons[a]?._order > this.props?.buttons[b]?._order ? 1 : -1)
                            .map((item: any, index:number) => {
                                return (
                                    <a href={item?.link_pulsante?._value} key={'toolbara'+index} target={'_blank'}>
                                    <div key={'bb_btn_'+index} className={'btn-background-color btn'}>
                                        {item?.etichetta_pulsante?._value}
                                    </div>
                                    </a>)
                            })
                        : null
                }
            </div>


        </div>

    }

}

BackBar.contextType = AppContext;
export default withNavigate(WithDialog(BackBar));

