import React, {RefObject, useRef} from "react";
import './About.css';
import {DialogPropTypes} from "../ui/dialog/DialogContext";
import {AppContext, AppContextType} from "../AppContext";
import {withNavigate} from "../WithNavigate";
import WithDialog from "../ui/dialog/WithDialog";
import firebase from "../firebase";
import {ImagesSlider} from "../ui/images-slider/ImagesSlider";
import CountUp from "react-countup";
import ListItem from "./ListItem";
import ScrollContainer from "react-indiana-drag-scroll";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, FreeMode, Lazy, Navigation, Pagination} from "swiper";
// @ts-ignore
import {Helmet} from "react-helmet";

interface AboutProps extends DialogPropTypes {
}

class About extends React.Component<AboutProps, any> {
    navigationPrevRef: RefObject<any>;
    navigationNextRef: RefObject<any>;

    swiper: any;
    swiperServizi: any;

    constructor(props: any) {
        super(props);

        this.state = {}
        this.swiper = React.createRef();
        this.swiperServizi = React.createRef();

        this.navigationPrevRef = React.createRef()
        this.navigationNextRef = React.createRef()
    }


    async componentDidMount() {
        const ctx = (this.context as AppContextType).appContext;

        const dbRef = firebase.database().ref(ctx.basePath + '/pagine/azienda');
        dbRef.on('value', snapshot => {

            this.setState({azienda: snapshot.val()})
        });
    }


    render() {
        return <div id={'about-page'}>

            {/* SEO */}
            <Helmet>
                {this.state?.azienda?.seo?.meta_titolo?._value ?
                    <title>{this.state?.azienda?.seo?.meta_titolo?._value +" - "+ process.env.REACT_APP_TITLE}</title> :
                    <title>{process.env.REACT_APP_TITLE}</title>}
                <meta name='description' content={this.state.azienda?.seo?.meta_descrizione?._value} />
                <meta name='keywords' content={this.state?.azienda?.seo?.meta_keywords?._value} />
            </Helmet>

            {/*Intro*/}
            <div data-aos="fade-in" data-aos-once="true" className={'intro-div padded'}>
                <p className={'intro-test-p font-48-600'}
                   dangerouslySetInnerHTML={{__html: this.state.azienda?.intro_testo?._value}}>
                </p>
            </div>

            {/*Image*/}
            <div id={'about-media-container'}>
                <div id={'about-media'} data-aos="fade-up" data-aos-once="true"
                     className={'img-container-home-div img-container-main-home-div mini-padded kreosoft-pointer'}>
                    {
                        this.state.azienda?.copertina?._list && Object.keys(this.state.azienda?.copertina?._list).length > 0 ?
                            <ImagesSlider autoplay={true} images={this.state.azienda?.copertina?._list}>
                            </ImagesSlider> : ''
                    }
                </div>
            </div>

            {/*Lista dati numerici*/}
            <div id={'numbers-list'} className={'padded'}>
                {
                    this.state?.azienda?.lista_dati_numerici ?

                        Object.keys(this.state?.azienda?.lista_dati_numerici?._list)
                            .filter((key: any) => this.state?.azienda?.lista_dati_numerici?._list[+key]?._id != 0)
                            .map(key => this.state?.azienda?.lista_dati_numerici?._list[key]).sort((item1, item2)=> item1?._order > item2?.order? -1:1)
                            .map((item: any, index: number) => {
                                return <div className={'number-container'}>
                                    <label id={'numbers-list-number'} className={'font-64-600'} data-aos="fade-in"
                                           data-aos-once="true" data-aos-delay={50* (index ?? 0)}>
                                        <CountUp end={item?.numero?._value} separator={''} enableScrollSpy={true}
                                                 scrollSpyOnce={true}/>
                                    </label>
                                    <label id={'numbers-list-etichetta'} className={'font-24-600'}>
                                        {item?.etichetta?._value}
                                    </label>
                                </div>

                            }) :
                        null
                }
            </div>


            {/* Blocchi titolo e testo */}
            {
                this.state.azienda?.blocchi_titolo_e_testo && this.state.azienda?.blocchi_titolo_e_testo?._list ?
                    Object.keys(this.state.azienda?.blocchi_titolo_e_testo?._list || {})?.filter((key: string) => key != "0")?.map((key: string) => this.state.azienda?.blocchi_titolo_e_testo?._list[key])?.sort((item1, item2)=> item1?._order > item2?.order? -1:1).map((item: any, index: number) => {
                        return <div className={'padded columns'}>
                            <div>
                                <p className={'font-40-600 about-titles'}>
                                    {item?.titolo?._value}
                                </p>
                            </div>
                            <div>
                                <p className={'font-14'} data-aos="fade-in" data-aos-once="true"
                                   dangerouslySetInnerHTML={{__html: item?.testo?._value}}>
                                </p>
                            </div>
                        </div>
                    }) :
                    null
            }

            {/* Blocchi elenco puntato */}
            <div id={'lists-container'}>
                {
                    this.state.azienda?.blocchi_elenco_puntato && this.state.azienda?.blocchi_elenco_puntato?._list ?
                        Object.keys(this.state.azienda?.blocchi_elenco_puntato?._list || {})?.filter((key: string) => key != "0")?.map((key: string) => this.state.azienda?.blocchi_elenco_puntato?._list[key])?.sort((item1, item2)=> item1?._order > item2?.order? -1:1).map((item: any, index: number) => {
                            return <div className={'list-b2b-container'}>
                                <div className={'padded columns'}>
                                    <div>
                                        <p className={'font-40-600 about-titles'}>
                                            {item?.titolo?._value}
                                        </p>
                                    </div>
                                    <div className={'list-items-container'}>
                                        {
                                            item?.elenco_puntato?._list ?

                                                Object.keys(item?.elenco_puntato?._list)
                                                    .filter((key: any) => item?.elenco_puntato?._list[+key]?._id != 0)
                                                    .map(key => item?.elenco_puntato?._list[key])
                                                    .sort((item1, item2)=> item1?._order > item2?.order? -1:1)
                                                    .map((el: any, index: number) => {
                                                        return <ListItem index={index} title={el?.titolo_cella?._value}
                                                                         description={el?.descrizione_cella?._value}
                                                                         gallery={el?.gallery_loghi?._list}
                                                                         key={'b2bli' + index}></ListItem>

                                                    }) :
                                                null
                                        }
                                    </div>
                                </div>

                                {
                                    Object.keys(item?.gallery?._list || {}) && Object.keys(item?.gallery?._list || {}).length>1?
                                        <div className="gallery-container b2b-gallery">

                                            <ScrollContainer className="gallery">

                                                {
                                                    Object.keys(item?.gallery?._list || {})?.filter((key: string) => key != "0")?.map((key: string) => item?.gallery?._list[key])?.sort((item1, item2)=> item1?._order > item2?.order? -1:1).map((immagine: any, index: number) => {
                                                        return <img data-aos="fade-in" data-aos-once="true"
                                                                    data-aos-delay={50* (index ?? 0)}
                                                                    key={'b2bco' + index} className="gallery-item background"
                                                                    src={immagine?.immagine?._value}></img>
                                                    })
                                                }

                                            </ScrollContainer>
                                        </div>
                                        :
                                        null
                                }

                            </div>
                        }) :
                        null
                }
            </div>


            {/* Slider B2C */}
            <div id={'b2c-slider'}>
                <div className={'padded columns'}>
                    <div>
                        <p className={'font-40-600 about-titles'}>
                            {this.state?.azienda?.slider_b2c?.etichetta_slider?._value}
                        </p>
                    </div>
                    <div>
                        <p className={'font-14'} data-aos="fade-in" data-aos-once="true"
                           dangerouslySetInnerHTML={{__html: this.state?.azienda?.slider_b2c?.descrizione_slider?._value}}>
                        </p>
                    </div>
                </div>

                {/* Slider */}


                <div id={'about-b2c-slider'}>

                    {

                        this.state?.azienda?.slider_b2c?.slider && this.state?.azienda?.slider_b2c?.slider?._list ?
                            <div id={'about-slider-container'} className={'padded'}>
                                <div className={'about-slider-button-container'}>
                                    <button ref={this.navigationPrevRef}
                                            className={'icon-freccia_left font-24-600'}></button>
                                    <button ref={this.navigationNextRef}
                                            className={'icon-freccia_right font-24-600'}></button>
                                </div>

                                <Swiper
                                    itemRef={this.swiper}
                                    effect={"slide"}
                                    lazy={true}
                                    navigation={{
                                        prevEl: this.navigationPrevRef.current,
                                        nextEl: this.navigationNextRef.current,
                                    }}
                                    spaceBetween={24}
                                    onBeforeInit={(swiper) => {
                                        if (swiper?.params?.navigation) {
                                            (swiper.params.navigation as any).prevEl = this.navigationPrevRef.current;
                                            (swiper.params.navigation as any).nextEl = this.navigationNextRef.current;
                                        }

                                    }}
                                    autoplay={{
                                        delay: 8000,
                                        disableOnInteraction: true
                                    }}
                                    observer={true}
                                    grabCursor={true}
                                    modules={[Lazy, Pagination, Navigation, Autoplay]}
                                    className="about-image-slider"
                                >

                                    {Object.keys(this.state?.azienda?.slider_b2c?.slider?._list)
                                        .filter((key: any) => this.state?.azienda?.slider_b2c?.slider?._list[+key]?._id != 0)
                                        .map((key: any) => this.state?.azienda?.slider_b2c?.slider?._list[key])
                                        .sort((a: any, b: any) => {
                                            return (a?._order > b?._order) ? 1 : -1
                                        }).map((item: any, index: number) => {
                                            return (
                                                <SwiperSlide key={"img_about_slider_" + index}>
                                                    <div
                                                        className="swiper-lazy about-slider-image"
                                                        style={{
                                                            backgroundImage: 'url(' + item?.immagine?._value + ')'
                                                        }
                                                        }
                                                    ></div>
                                                    <div className={'columns about-project-description-container'}>
                                                        <p className={'about-project-label font-24-600'}>
                                                            {item?.etichetta_progetto?._value}
                                                        </p>
                                                        <p className={'font-14'} data-aos="fade-in" data-aos-once="true"
                                                           dangerouslySetInnerHTML={{__html: item?.descrizione_progetto?._value}}>
                                                        </p>
                                                    </div>
                                                </SwiperSlide>


                                            );
                                        })}
                                </Swiper>
                            </div>

                            : null
                    }

                </div>
            </div>
        </div>

    }

}

About.contextType = AppContext;
export default withNavigate(WithDialog(About));

