import React from "react";
import logoAsset from '../assets/logo.png';
import {Link, NavLink} from "react-router-dom";
import './Toolbar.css';
import {AppContext, AppContextType} from "../AppContext";
import firebase from "../firebase";

class ToolbarProps {
}

class ToolbarState {
    mobileMenuOpened = false;
    logo?: string;
    menuInToolbar: any[] = [];
}


export class Toolbar extends React.Component<ToolbarProps, ToolbarState> {



    constructor(props: any) {
        super(props);

        this.state = {
            mobileMenuOpened: false,
            menuInToolbar: []
        }
    }


    async componentDidMount() {
        const ctx = (this.context as AppContextType).appContext;

        this.getMenuLabels(ctx);
    }

    getMenuLabels(ctx: any) {
        firebase.database().ref(ctx.basePath + '/impostazioni/etichette/menu').get().then(async res => {
                const val = res.val();
                let menu: any[] = []
                if (val) {
                    const menuKeys = Object.keys(val).filter((key: string) => !key.startsWith('_')).sort((a, b) => (val[a]?._order > val[b]?._order) ? 1 : -1);

                    for (let menuKey of menuKeys) {
                        let isVisible = await firebase.database().ref(ctx.basePath + '/pagine/' + menuKey + '/_visibility').get();

                        if (isVisible.val()) {
                            menu.push({
                                description: val[menuKey]?.['_value'],
                                url: val[menuKey]?._url
                            })
                        }
                    }
                }
                this.setState({
                    menuInToolbar: menu,
                    logo:  logoAsset
                })
            }
        );
    }



    toggleMenu() {
        let isMenuOpened = this.state.mobileMenuOpened;
        this.setState({
            mobileMenuOpened: !isMenuOpened
        })
    }

    render() {

        return <>
            <div className={'toolbar padded'}>

                <div className={'logo-div'}>
                    <Link key='home-nav' to='' className={'a-toolbar'}>
                        {
                            this.state?.logo?
                                <img  data-aos="fade"
                                      data-aos-once="true" className="logo-img" src={this.state.logo}/>
                                : null
                        }

                    </Link>
                </div>
                <div className={'menu-div hide-mobile'}>
                    {
                        Object.keys(this.state?.menuInToolbar).map((key:any)=> this.state.menuInToolbar[key])?.map((menu, index) =>
                            <NavLink  data-aos="fade"
                                      data-aos-once="true" key={'menu' + index}
                                      data-aos-delay={50*index}
                                      className={({isActive}) => (isActive ? "menu-voice-div menu-item-selected font-14" : "menu-voice-div font-14")}
                                      to={menu.url}>
                                {menu?.description != 'Assistenza'?
                                    <span>{menu.description}</span>:
                                    <a className={'assistenza-btn toolbar-green-button'}>
                                        <i className={'icon-customer_care toolbar-icon toolbar-green-button green-outline-toolbar-icon'}></i>
                                        <span>{menu.description}</span>
                                    </a>

                                }

                            </NavLink>
                        )

                    }
                    <a className={'toolbar-green-button font-14'} href={'https://kate.kreosoft.cloud'} target={'_blank'}>
                        <span className={'hide-tablet'}>Il tuo account</span>
                        <i className={'icon-kate toolbar-icon hide-desktop green-outline-toolbar-icon'}></i>
                    </a>
                </div>
                <i className={"icon icon-menu"} onClick={() => this.toggleMenu()}></i>

            </div>




            <div className={'mobile-menu  ' + (this.state.mobileMenuOpened ? 'mobile-menu-opened' : '')}>

                {
                    this.state?.menuInToolbar?.map((menu, index) =>
                        <NavLink key={'menumobile-' + index}

                                 className={({isActive}) => (isActive ? "mobile-menu-voice-div menu-item-selected font-40-600" : "mobile-menu-voice-div font-40-600")}
                                 onClick={() => this.toggleMenu()} to={menu.url}>
                            {menu.description}
                        </NavLink>
                    )
                }
                <a className={( "mobile-menu-voice-div font-40-600")} href={'https://kate.kreosoft.cloud'} target={'_blank'}>
                    Il tuo account
                </a>
            </div>
        </>
    }
}

Toolbar.contextType = AppContext;

