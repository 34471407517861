import React from "react";
import './News-Detail.css';
import {DialogPropTypes} from "../../ui/dialog/DialogContext";
import {AppContext, AppContextType} from "../../AppContext";
import {withNavigate} from "../../WithNavigate";
import WithDialog from "../../ui/dialog/WithDialog";
import firebase from "../../firebase";
import BackBar from "../../ui/back-bar/BackBar";
import ScrollContainer from "react-indiana-drag-scroll";
import NewsCell from "../../news/news-cell/News-Cell";
import SoftwareCell from "../../software/software-cell/Software-Cell";
import {ImagesSlider} from "../../ui/images-slider/ImagesSlider";
import moment from "moment";
import 'moment/locale/it'
// @ts-ignore
import {Helmet} from "react-helmet";
import newsList from "../news-list/News-List";

interface NewsDetailProp extends DialogPropTypes {
    navigate: any;
    params:any;
    key:any;
}

class NewsDetail extends React.Component<NewsDetailProp, any> {

    plainDescriptionText = '';

    constructor(props: any) {
        super(props);

        this.state = {}
    }


    async componentDidMount() {
        const ctx = (this.context as AppContextType).appContext;
        const newsKey = this.props.params.key;


        const dbRef = firebase.database().ref(ctx.basePath + '/news/_list/' + newsKey);
        dbRef.on('value', snapshot => {
            let news = snapshot.val();
            this.plainDescriptionText = new DOMParser()
                .parseFromString(news?.sottotitolo?._value, "text/html")
                .documentElement.textContent as string;
            this.setState({news: news})
        });

        const dbRefLabels = firebase.database().ref(ctx.basePath + '/impostazioni/etichette/news');
        dbRefLabels.on('value', snapshot => {
            console.log(snapshot.val())
            this.setState({labels: snapshot.val()})
        });


    }



    render() {

        return <div id={'news-detail-page'}>

            {/* SEO */}
            <Helmet>
                {this.state.news?._description?._value ?
                    <title>{this.state.news?._description?._value +" - "+ process.env.REACT_APP_TITLE}</title> :
                    <title>{process.env.REACT_APP_TITLE}</title>}
                <meta name='description' content={this.plainDescriptionText as string} />
                <meta name='keywords' content={this.state.news?.tags?._value} />
            </Helmet>

            <BackBar buttons={this.state.news?.pulsanti?._list}></BackBar>

            <div className={'news-detail-div padded'}  data-aos="fade-in" data-aos-once="true">

                    <div className={'info-software-div'}>

                        <p className={'font-14 subtitle news-date'}>{moment(this.state.news?.data?._value).format('DD/MM/YYYY')}</p>
                        <p className={'font-40-600 title'}>{this.state.news?._description?._value}</p>
                        <p className={'font-14 subtitle'} dangerouslySetInnerHTML=
                            {{__html:this.state.news?.sottotitolo?._value}}></p>

                        {/*Image*/}
                        <div id={'software-media-container'}>
                            <div id={'news-media'} data-aos="fade-up" data-aos-once="true"
                                 className={'img-container-home-div img-container-main-home-div kreosoft-pointer'}>
                                {
                                    this.state.news?.gallery?._list && Object.keys(this.state.news?.gallery?._list).length > 0 ?
                                        <ImagesSlider autoplay={true} images={Object.keys(this.state.news?.gallery?._list).filter((s:string)=> s!="0").map(key=> this.state.news?.gallery?._list[key])}>
                                        </ImagesSlider> : ''
                                }
                            </div>
                        </div>


                        {
                            this.state.news?.sezioni?._list? Object.keys(this.state.news?.sezioni?._list)
                                .filter((s:string)=> s!="0").sort((a,b) => this.state?.news?.sezioni?._list[a]?._order >this.state?.news?.sezioni?._list[b]?._order? 1: -1).map(
                                    s=>
                                        <div key={'s_'+s} className={'software-section padded'}>

                                            <p className={'section-title font-24-600'}  dangerouslySetInnerHTML=
                                                {{__html:this.state.news?.sezioni._list[s]?.titolo_sezione?._value}}></p>
                                            <p key={'sp_'+s} className={'section-description font-14'}  dangerouslySetInnerHTML=
                                                {{__html:this.state.news?.sezioni._list[s]?.descrizione_sezione?._value}}>
                                            </p>


                                            {
                                                this.state.news?.sezioni._list[s]?.gallery_sezione && this.state.news?.sezioni._list[s]?.gallery_sezione?._list && Object.keys(this.state.news?.sezioni._list[s]?.gallery_sezione?._list)?.length>1?
                                                    <div className={'cursor-grab'}>
                                                        <ScrollContainer className="gallery-detail">

                                                            {
                                                                Object.keys(this.state.news?.sezioni._list[s]?.gallery_sezione?._list||{})?.
                                                                filter((key:string)=> key!="0")?.
                                                                map((key:string)=>this.state.news?.sezioni._list[s]?.gallery_sezione?._list[key])?.sort((item1, item2)=> item1?._order > item2?.order? -1:1).
                                                                map((immagine:any, index:number) => {
                                                                    return <img data-aos="fade-in" data-aos-once="true" data-aos-delay={50*(index??0)} key={'phco'+ index} className="gallery-item-detail background" src={immagine?.immagine?._value} ></img>
                                                                })
                                                            }

                                                        </ScrollContainer>
                                                    </div>
                                                    : null
                                            }


                                        </div>
                                ) : ""
                        }
                        
                    </div>
            </div>

            {/* Software correlati*/}
            {
                this.state?.news?.software_correlati?._list && Object.keys(this.state.news?.software_correlati._list) &&
                Object.keys(this.state.news?.software_correlati._list)?.length>1?
                    <div id={'related-software'} className={'padded'}>
                        <p className={'font-40-600 title-label'}>{this.state.labels?.software_correlati_titolo?._value}</p>

                        <p className={'font-14 subtitle-label'}
                           dangerouslySetInnerHTML={{__html:this.state.labels?.software_correlati_descrizione?._value}}></p>

                        <div className={'software-list-container-div'}>
                            {
                                this.state?.news?.software_correlati?._list ?
                                    Object.keys(this.state.news?.software_correlati._list)
                                        .sort((key1, key2)=> this.state.news?.software_correlati?._list[key1]?._order > this.state.news?.software_correlati?._list[key2]?.order? -1:1)
                                        .filter(swK=>swK!="0").map((swK: any, index: number) => {
                                        let sw = this.state?.news?.software_correlati?._list[swK];
                                        return (
                                            <SoftwareCell
                                                key={'sc' +sw?._id}
                                                index={index}
                                                softwareKey={sw?._linkedId}>
                                            </SoftwareCell>
                                        );
                                    }) : "" }
                        </div>
                    </div>:
                    null
            }

            {/* News correlate*/}
            {
                this.state?.news?.news_correlate?._list && Object.keys( this.state?.news?.news_correlate?._list) &&
                Object.keys( this.state?.news?.news_correlate?._list)?.length>1?
                    <div id={'related-news'} className={'news-background padded'}>

                        <p className={'font-40-600 title-label'}>{this.state.labels?.news_correlati_titolo?._value}</p>

                        <p className={'font-14 subtitle-label'}
                           dangerouslySetInnerHTML={{__html:this.state.labels?.news_correlati_descrizione?._value}}></p>

                        <div className={'software-list-container-div'}>
                            {
                                this.state?.news?.news_correlate?._list ?
                                    Object.keys(this.state.news?.news_correlate._list)
                                        .filter(nK=>nK!="0")
                                        .sort((key1, key2)=> this.state.news?.news_correlate?._list[key1]?._order > this.state.news?.news_correlate?._list[key2]?.order? -1:1)
                                        .map((nK: any, index: number) => {
                                        let news = this.state?.news?.news_correlate?._list[nK];
                                        return (
                                            <NewsCell
                                                key={'new_' +news?._id}
                                                index={index}
                                                newsKey={news?._linkedId}>
                                            </NewsCell>
                                        );
                                    }) : "" }
                        </div>

                    </div> :
                    null
            }



        </div>

    }

}

NewsDetail.contextType = AppContext;
export default withNavigate(WithDialog(NewsDetail));

