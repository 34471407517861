import React, {useContext} from 'react';
import './App.css';
import Main from "./Main"
import {AppContext, AppContextType} from "./AppContext";
import DialogProvider from "./ui/dialog/DialogProvider";
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import firebase from "firebase/compat/app";

// @ts-ignore
import AOS from 'aos';
import "aos/dist/aos.css";
// @ts-ignore
import {Helmet} from "react-helmet";
import {CircularProgress} from "@mui/material";
// @ts-ignore
import DOMPurify from 'dompurify';

interface AppState {
  isDraftEnabled: boolean;
  render: boolean;
  theme?: string;
  style?: string;
}

class App extends React.Component<{}, AppState> {
  customCursor:any;
  customCursorPointer:any;

  constructor(props: any) {
    super(props);
    this.state = {isDraftEnabled: false,render:false};

    this.customCursor = React.createRef();
    this.customCursorPointer = React.createRef();
  }


  componentDidMount() {


    AOS.init({
      // initialise with other settings
      duration: 1000
    });

    let ctx = (this.context as AppContextType).appContext;

    firebase
        .database()
        .ref(ctx.basePath + "/impostazioni/css")
        .on("value",
            (res) => {
              if(res.val()){
                const css = res.val();
                this.setState({
                  style: css.style?._value,
                  theme: css.theme?._value
                })
              }

            });

    if (ctx.draft) {
      firebase.database().ref(ctx.basePath + "/projectApiKey").once('value').then(res => {
        if (res.val() === ctx.projectApiKey) {
          this.setState({isDraftEnabled: true,render:true});
        } else
          this.setState({isDraftEnabled: false,render:true});
      })
    }else
      this.setState({isDraftEnabled: false,render:true});


  }


  render() {


    //const isDraftEnabled = this.state.isDraftEnabled

    return (
        <>
          <head>
            <style dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(this.state?.theme)
            }}>

            </style>
            <style dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(this.state?.style)
            }}>
            </style>
          </head>
          <div className={'app-div'}>

            {
              this.state.render && (!(this.context as AppContextType).appContext.draft || this.state.isDraftEnabled) ?
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DialogProvider>
                      <Main></Main>
                    </DialogProvider>
                  </LocalizationProvider>
                  :
                  this.state.render && (!this.state.isDraftEnabled) ?
                      <NotAvailPreview></NotAvailPreview>
                      :
                      <CircularProgress className={'progress-center'} />
            }

            {/*<div id="custom-cursor" ref={this.customCursor} style={{left: this.state.left, top: this.state.top}}></div>*/}
          </div>
        </>
    )
  }


}

export default App;

App.contextType = AppContext;

const NotAvailPreview = () => {
  return <div className={'not-avail-preview-div'}>
    <p className={'text-align-c'}>Anteprima non disponibile per questo progetto</p>
  </div>
}

