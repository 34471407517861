import React from "react";
import './CustomerService.css';
import {DialogPropTypes} from "../ui/dialog/DialogContext";
import {AppContext, AppContextType} from "../AppContext";
import {withNavigate} from "../WithNavigate";
import WithDialog from "../ui/dialog/WithDialog";
import firebase from "../firebase";
// @ts-ignore
import DOMPurify from 'dompurify';
// @ts-ignore
import {Helmet} from "react-helmet";

interface CustomerServiceProps extends DialogPropTypes {
}

class CustomerService extends React.Component<CustomerServiceProps, any> {

    constructor(props: any) {
        super(props);

        this.state = {}
    }


    async componentDidMount() {
        const ctx = (this.context as AppContextType).appContext;

        const dbRef = firebase.database().ref(ctx.basePath + '/pagine/assistenza');
        dbRef.on('value', (snapshot: any) => {
            let res = snapshot?.val();
            console.log(res)
            this.setState({
                assistenza: res
            })
        });
    }


    render() {

        return <div id={'customer-service-page'} data-aos="fade-in" data-aos-once="true">

            {/* SEO */}
            <Helmet>
                {this.state?.assistenza?.seo?.meta_titolo?._value ?
                    <title>{this.state?.assistenza?.seo?.meta_titolo?._value +" - "+ process.env.REACT_APP_TITLE}</title> :
                    <title>{process.env.REACT_APP_TITLE}</title>}
                <meta name='description' content={this.state.assistenza?.seo?.meta_descrizione?._value} />
                <meta name='keywords' content={this.state?.assistenza?.seo?.meta_keywords?._value} />
            </Helmet>

            {/* Elenco assistenza */}
            <div className={'customer-service-container padded'}>
                {
                    this.state?.assistenza?.elenco_assistenza && this.state?.assistenza?.elenco_assistenza?._list ?
                        Object.keys(this.state?.assistenza?.elenco_assistenza?._list)
                            .filter((key: any) => this.state?.assistenza?.elenco_assistenza?._list[+key]?._id != 0)
                            .map(key => this.state?.assistenza?.elenco_assistenza?._list[key])
                            .map((item: any, mainIndex: number) => {
                                return <div className={'customer-service-assistance-flex-container'}>
                                    <div>
                                        <p className={'title font-40-600 accent-text'}>{item.titolo_sezione?._value}</p>
                                        <p className={'subtitle-p font-14 accent-text'}
                                           dangerouslySetInnerHTML={{__html: item.descrizione_sezione?._value}}>
                                        </p>
                                    </div>
                                    <div>
                                        {
                                            item?.elementi_verdi && item?.elementi_verdi?._list ?
                                                Object.keys(item?.elementi_verdi?._list)
                                                    .filter((key: any) => item?.elementi_verdi?._list[+key]?._id != 0)
                                                    .map(key => item?.elementi_verdi?._list[key])
                                                    .map((verde: any, index: number) => {
                                                        return <div className={'green-container'} data-aos="fade-in" data-aos-once="true" data-aos-delay={50*((index??1)+(mainIndex+1??1))}>
                                                            <div>
                                                                {verde?.immagine?._value?
                                                                    <img src={verde?.immagine?._value} className={'img-customer-care'}/>:
                                                                    <p className={'font-24-600'}>{verde?.titolo?._value}</p>
                                                                }

                                                                <p className={'font-14 accent-text subtitle-p'}
                                                                   dangerouslySetInnerHTML={{__html: verde?.descrizione?._value}}></p>
                                                            </div>
                                                            <div>
                                                                <a href={verde?.pulsante?.link_pulsante?._value}
                                                                   target={'_blank'}>
                                                                    <div className={'btn btn-background-color'}>
                                                                        {verde?.pulsante?.etichetta_pulsante?._value}
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    }) : null
                                        }
                                    </div>
                                </div>

                            })
                        : null
                }
            </div>

            {/* Elenco elementi griglia */}
            <div id={'service-grid'} className={'customer-service-container padded'}>
                {
                    this.state?.assistenza?.elenco_elementi_griglia && this.state?.assistenza?.elenco_elementi_griglia?._list ?
                        Object.keys(this.state?.assistenza?.elenco_elementi_griglia?._list)
                            .filter((key: any) => this.state?.assistenza?.elenco_elementi_griglia?._list[+key]?._id != 0)
                            .map(key => this.state?.assistenza?.elenco_elementi_griglia?._list[key])
                            .map((item: any, index: number) => {
                                return <div className={'customer-service-support-flex-container'}>
                                    <div>
                                        <p className={'title font-40-600 accent-text'}>{item.titolo?._value}</p>
                                        <p className={'subtitle-p font-14 accent-text'}
                                           dangerouslySetInnerHTML={{__html: item.descrizione?._value}}>
                                        </p>
                                    </div>
                                    <div className={'software-list-container-div'}>
                                        {
                                            item?.griglia && item?.griglia?._list ?
                                                Object.keys(item?.griglia?._list)
                                                    .filter((key: any) => item?.griglia?._list[+key]?._id != 0)
                                                    .map(key => item?.griglia?._list[key])
                                                    .map((verde: any, index: number) => {
                                                        return <div className={'grey-container software-cell-div'} >
                                                            <div>
                                                                <img src={verde?.immagine?._value} className={'img-support'}/>
                                                                <p className={'font-24-600'}>{verde?.titolo?._value}</p>
                                                                <p className={'font-14 accent-text subtitle-p'}
                                                                   dangerouslySetInnerHTML={{__html: verde?.descrizione?._value}}></p>
                                                            </div>
                                                            <div className={'support-btns-container'}>

                                                                {verde?.elenco_pulsanti && verde?.elenco_pulsanti?._list ?
                                                                    Object.keys(verde?.elenco_pulsanti?._list)
                                                                        .filter((key: any) => verde?.elenco_pulsanti?._list[+key]?._id != 0)
                                                                        .map(key => verde?.elenco_pulsanti?._list[key])
                                                                        .map((btn: any, index: number) => {
                                                                            return <a href={btn?.link_pulsante?._value}
                                                                                      target={'_blank'}>
                                                                                <p id={'read-more-container'}>
                                                                        <span className={'read-more font-14'}>
                                                                            {btn?.etichetta_pulsante?._value}
                                                                        </span>
                                                                                </p>
                                                                            </a>
                                                                        }) : null}


                                                            </div>
                                                        </div>
                                                    }) : null
                                        }
                                    </div>
                                </div>

                            })
                        : null
                }
            </div>

        </div>

    }

}

CustomerService.contextType = AppContext;
export default withNavigate(WithDialog(CustomerService));

