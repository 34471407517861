import React from "react";
import './Software-Cell.css';
import {DialogPropTypes} from "../../ui/dialog/DialogContext";
import {AppContext, AppContextType} from "../../AppContext";
import {withNavigate} from "../../WithNavigate";
import WithDialog from "../../ui/dialog/WithDialog";
import firebase from "../../firebase";
import {Link} from "react-router-dom";
import {goTo} from "../../Utils";


interface SoftwareCellProp extends DialogPropTypes {
    navigate: any;
    params:any;
    softwareKey:any;
    index:number;
}

class SoftwareCell extends React.Component<SoftwareCellProp, any> {

    constructor(props: any) {
        super(props);

        this.state = {}
    }


    async componentDidMount() {
        const ctx = (this.context as AppContextType).appContext;
        const softwareKey = this.props.softwareKey;

        const dbRef = firebase.database().ref(ctx.basePath + '/software/_list/' + softwareKey);
        dbRef.on('value', snapshot => {
            this.setState({software: snapshot.val()})
        });


    }

    goToSoftware(){
        this.props.navigate('/prodotti/'+ this.state.software._id)
    }

    render() {

        return <div onClick={()=>this.goToSoftware()}
            className={'software-cell-div'}  data-aos="fade-in" data-aos-once="true" data-aos-delay={50*(this.props.index??0)}>
            {
                this.state?.software?.gallery && this.state?.software?.gallery?._list ?
                    <div className={'img'}
                         style={{backgroundImage: `url(${Object.keys(this.state?.software?.gallery?._list).map(key => this.state?.software?.gallery?._list[+key])?.filter((i: any) => i._id != 0 && i.immagine?._mediaType?.startsWith('image'))[0]?.immagine?._value})`}}></div>
                    : null
            }
            <div className={'info'}>
                <p className={'font-24-600'}>
                    {this.state.software?._description?._value}
                </p>
                <p className={'font-14'} dangerouslySetInnerHTML={{__html: this.state.software?.sottotitolo?._value}}>

                </p>
            </div>



        </div>



    }

}

SoftwareCell.contextType = AppContext;
export default withNavigate(WithDialog(SoftwareCell));

