
export const getHref = (url:string) => {
    return url?.indexOf('http')==-1?('./' +
            url) :
        url
}

export const getTarget = (url:string) => {
    return url?.indexOf('http')==-1?'_self': '_blank';
}

export const goTo = (url:string,e?:any) =>{
    window.open(getHref(url),getTarget(url));
    e.preventDefault();
}

export const mailTo = (url:string,e?:any) =>{
    window.location.href = url;
    e.preventDefault();
}
