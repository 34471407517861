import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {
    DialogWidthType,
    DialogPropTypes,
    OpenDialogType,
    EmptyFunctionType,
    dialogContext
} from "./DialogContext";
import "./Dialog.css";

interface StateTypes {
    value: DialogPropTypes;
    isOpen: boolean;
    title: string;
    okText?: string;
    cancelText?: string;
    width?: DialogWidthType;
    component: React.ReactNode;
    okCallback: EmptyFunctionType;
    cancelCallback?: EmptyFunctionType;
    classes?:string;
}

interface PropTypes {
    children: React.ReactNode;
}


class DialogProvider extends React.Component<PropTypes, StateTypes> {
    constructor(props: PropTypes) {
        super(props);

        this.state = {
            isOpen: false,
            title: "",
            okText: "Applica",
            cancelText: "Cancel",
            width: "md",
            component: null,
            okCallback: (data:any) => {
            },
            cancelCallback: () => {
            },
            value: {
                openDialog: this.open,
                closeDialog: this.close
            },
            classes:""
        };
    }

    public open: OpenDialogType = ({
                                       component,
                                       title,
                                       okCallback,
                                       cancelCallback,
                                       width,
                                       okText,
                                       cancelText,
                                       classes
                                   }) => {
        this.setState({
            component,
            title,
            okCallback,
            cancelCallback,
            width,
            okText,
            cancelText,
            isOpen: true,
            classes
        });
    };

    public close = (): void => {
        this.setState({ isOpen: false });
    };

    public handleCloseClick = () => {
        if (this.state.cancelCallback) {
            this.state.cancelCallback();
        } else {
            this.close();
        }
    };



    render() {
        const {
            value,
            isOpen,
            width,
            title,
            okText,
            cancelText,
            component,
            classes
        } = this.state;

        return (
            <dialogContext.Provider value={value}>
                <Dialog
                    open={isOpen}
                    onClose={this.handleCloseClick}
                    maxWidth={width}
                    fullWidth
                    className={classes}
                >
                    {title && <DialogTitle>
                        <div className={'flex-row '}>
                            <p className={'dialog-title'}>{title}</p>
                            <p className={'dialog-close-icon-container'}>
                                <span className={'close-dialog-label'}>Chiudi</span>
                                <i className={'icon-chiudi icon pointer'} onClick={this.close}></i>
                            </p>
                        </div>
                    </DialogTitle>}
                    <DialogContent>{component}</DialogContent>
                    <DialogActions className={'dialog-actions'}>
                        {/*  <Button onClick={this.handleCloseClick} color="secondary">
                            {cancelText}
                        </Button>*/}
                        <button className={'dialog-ok-button'} onClick={this.state.okCallback.bind(this,this.state)} >
                            <i className={'icon-avanti icon'} />
                            <span>{okText}</span>
                        </button>
                    </DialogActions>
                </Dialog>
                {this.props.children}
            </dialogContext.Provider>
        );
    }
}

export default DialogProvider;
