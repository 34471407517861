import React from "react";
import {AppContext, AppContextType} from "../AppContext";
import firebase from "../firebase";
import './Footer.css';
import CountUp from "react-countup";

export class FooterState {
    footer?: any;
}

export class Footer extends React.Component<{}, FooterState> {

    constructor(props: any) {
        super(props);

        this.state = {}
    }


    async componentDidMount() {
        const ctx = (this.context as AppContextType).appContext;
        firebase.database().ref(ctx.basePath + '/footer').on('value', res => {
            const footer = res.val();
            this.setState({footer})
        })
    }

    render() {
        return <div id={'footer'}>
            <div className={'scroll-no-overflow'}>
                <div className={'scrolling-bar-container'}>
                    <div className={'scrolling-bar'}>
                        {
                            this.state?.footer?.partners ?
                                Object.keys(this.state?.footer?.partners?._list)
                                    .filter((key: any) => this.state?.footer?.partners?._list[+key]?._id != 0)
                                    .map(key => this.state?.footer?.partners?._list[key]).sort((item1, item2)=> item1?._order > item2?.order? -1:1)
                                    .map((item: any, index: number) => {
                                        return <img src={item?.immagine?._value} key={'scrimg'+index} />
                                    }) :
                                null
                        }
                    </div>
                    <div className={'scrolling-bar'}>
                        {
                            this.state?.footer?.partners ?
                                Object.keys(this.state?.footer?.partners?._list)
                                    .filter((key: any) => this.state?.footer?.partners?._list[+key]?._id != 0)
                                    .map(key => this.state?.footer?.partners?._list[key])
                                    .map((item: any, index: number) => {
                                        return <img src={item?.immagine?._value} key={'fimg'+index} />
                                    }) :
                                null
                        }
                    </div>
                </div>
            </div>
            <div className={'contacts-div padded'}>

                <div className={'flex-row-mobile-column contacts column-gap-4-vmin'}>
                    <p className={'contacts-detail'}
                       dangerouslySetInnerHTML={{__html: this.state.footer?.sezione_contatti?.paragrafo_contatti?._value}}>

                    </p>

                    <div className={'assistance-and-info-container'}>

                        <div className={'footer-column'}>
                            {/*<b>{this.state.footer?.colonna_1?.titolo?._value}<br/></b>*/}

                            <p className={'font-18'}
                               dangerouslySetInnerHTML={{__html: this.state.footer?.colonna_1?.descrizione._value}}></p>

                            <div className={'footer-row btns-row'}>

                                <a href= {this.state.footer?.colonna_1?.contatta_link?.link?._value}>
                                <div className={'btn btn-background-white'}>
                                    {this.state.footer?.colonna_1?.contatta_link?.etichetta?._value}
                                </div>
                                </a>


                            </div>


                        </div>


                        <div className={'column-2-container'}>

                            <div id={'address'} className={'footer-row'}>
                                {/*<b>{this.state.footer?.colonna_2?.titolo?._value}<br/></b>*/}

                                <p className={'font-14'}
                                   dangerouslySetInnerHTML={{__html: this.state.footer?.colonna_2?.indirizzo._value}}></p>

                                <p className={'font-14'}
                                   dangerouslySetInnerHTML={{__html: this.state.footer?.colonna_2?.orario._value}}></p>

                            </div>


                        </div>

                    </div>

                    <div id={'copyright-container'} className={'assistance-and-info-container'}>
                        <div className={'copyright font-12-spacing'}
                             dangerouslySetInnerHTML={{__html: this.state.footer?.copyright?._value}}>
                        </div>
                        <div className={'footer-row contacts-social footer-column'}>

                            <div className={'font-24-600'}>
                                {this.state.footer?.colonna_2?.social && this.state.footer?.colonna_2?.social?._list && Object.keys(this.state.footer?.colonna_2?.social?._list)?.length > 0 ?
                                    <div className={''}>

                                        {Object.keys(this.state.footer?.colonna_2?.social?._list).filter((key: any) => this.state.footer?.colonna_2?.social?._list[+key]?._id != 0)
                                            .map(key => this.state.footer?.colonna_2?.social?._list[key]).map((item: any, index: number) => {
                                            return <div className={'social-a'} key={'contacts-1-' + index}>
                                                <a href={item.link?.['_value']} target="_blank"
                                                   rel="noreferrer">
                                                    {
                                                        item.titolo?.['_value'] && item.titolo?.['_value']?.indexOf('icon-') == -1 ?
                                                            item.titolo?.['_value'] :
                                                            <i className={item?.titolo?.['_value'] + ' footer-icon icon-white'}></i>
                                                    }


                                                </a>
                                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                            </div>

                                        })}
                                    </div>
                                    : null
                                }
                            </div>




                        </div>
                    </div>
                </div>


            </div>


        </div>
    }

}

Footer.contextType = AppContext;
