import React from "react";
import './Software-List.css';
import {DialogPropTypes} from "../../ui/dialog/DialogContext";
import {AppContext, AppContextType} from "../../AppContext";
import {withNavigate} from "../../WithNavigate";
import WithDialog from "../../ui/dialog/WithDialog";
import firebase from "../../firebase";
import SoftwareCell from "../software-cell/Software-Cell";
// @ts-ignore
import {Helmet} from "react-helmet";

interface SoftwareListProps extends DialogPropTypes {
    navigate: any;
    params:any;
    key:any;
}

class SoftwareList extends React.Component<SoftwareListProps, any> {

    constructor(props: any) {
        super(props);

        this.state = {}
    }


    async componentDidMount() {
        const ctx = (this.context as AppContextType).appContext;

        const softwareCategoryStringFromOutside = this.props.params.key;

        const dbRef = firebase.database().ref(ctx.basePath + '/pagine/software');
        dbRef.on('value', snapshot => {
            let res = snapshot?.val();

            const categories:any = [].concat(Object.values(res.categorie_software?._list)??[]);

            let firstCategoryId = 1;
            if (categories) {
                firstCategoryId = categories[0]?._id || 1;
            }
            if(softwareCategoryStringFromOutside){
                const catFromOutside = categories.find((c:any) => c.titolo_categoria?._value?.toLowerCase() == softwareCategoryStringFromOutside?.toLowerCase());
                if(catFromOutside){
                    firstCategoryId = catFromOutside?._id;
                }

            }
            this.setState({
                software: snapshot.val(),
                currentCategoryId: firstCategoryId}, () =>{
                this.getSoftwareInCategory(firstCategoryId)
            })
        });
    }

    setCurrentCategoryId(id: number) {
        this.setState({
            currentCategoryId: +id,
        }, ()=>{
            this.getSoftwareInCategory(+id);
        });

    }

    getSoftwareInCategory(id: number) {
        this.setState({
            softwareInCategory: []
        }, () =>{
            let software = (this.state?.software?.categorie_software?._list??[])?.find((item:any) => item?._id == id && item?._id!=0)?.software_collegati?._list?.filter((item:any) => item?._id!=0);

            software = [].concat(Object.values(software)??[])?.sort((a:any,b:any)=> a._order >= b._order ? 1 : -1);

            this.setState({softwareInCategory: software});
        })


    }


    render() {

        return <div id={'software-page'}>

            {/* SEO */}
            <Helmet>
                {this.state?.software?.seo?.meta_titolo?._value ?
                    <title>{this.state?.software?.seo?.meta_titolo?._value +" - "+ process.env.REACT_APP_TITLE}</title> :
                    <title>{process.env.REACT_APP_TITLE}</title>}
                <meta name='description' content={this.state.software?.seo?.meta_descrizione?._value} />
                <meta name='keywords' content={this.state?.software?.seo?.meta_keywords?._value} />
            </Helmet>

            {/*Intro*/}
            <div data-aos="fade-in" data-aos-once="true" className={'intro-div padded'}>

                <p className={'intro-test-p font-48-600'}
                   dangerouslySetInnerHTML={{__html: this.state.software?.intro_testo?._value}}>

                </p>

            </div>

            {/*Griglia sofware con categorie*/}
            <div id={'software-grid'} className={'padded'}>
                <div id={'software-categories'}>
                {
                    Object.keys(this.state.software?.categorie_software?._list||{})?.
                    filter((key:string)=> key!="0")?.
                    map((key:string)=>this.state.software?.categorie_software?._list[key])?.sort((item1, item2)=> item1?._order > item2?.order? -1:1).
                    map((categoria:any, index:number) => {
                        return <div id={categoria?.titolo_categoria?._value} key={'sw' + index}
                                    className={'category-chip font-14 ' +((categoria?._id) === this.state?.currentCategoryId ? 'selected-category' : '')}
                                    onClick={() => this.setCurrentCategoryId(+(categoria?._id))}>
                            {categoria?.titolo_categoria?._value}
                        </div>
                    })
                }
                </div>
                <div className={'software-list-container-div'}>
                    {
                        this.state?.softwareInCategory?.map((sw: any, index: number) => {
                            return (
                                <SoftwareCell
                                    key={'sc' +sw?._id}
                                    index={index}
                                    softwareKey={sw?._linkedId}>

                                </SoftwareCell>
                            );
                        })}
                </div>
            </div>

        </div>

    }

}

SoftwareList.contextType = AppContext;
export default withNavigate(WithDialog(SoftwareList));

