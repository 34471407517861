import React from "react";
import './Contact.css';
import {DialogPropTypes} from "../ui/dialog/DialogContext";
import {AppContext, AppContextType} from "../AppContext";
import {withNavigate} from "../WithNavigate";
import WithDialog from "../ui/dialog/WithDialog";
import firebase from "../firebase";
// @ts-ignore
import DOMPurify from 'dompurify';
// @ts-ignore
import {Helmet} from "react-helmet";

interface ContactProps extends DialogPropTypes {
}

class Contact extends React.Component<ContactProps, any> {

    constructor(props: any) {
        super(props);

        this.state = {}
    }


    async componentDidMount() {
        const ctx = (this.context as AppContextType).appContext;

        const dbRef = firebase.database().ref(ctx.basePath + '/pagine/contatti');
        dbRef.on('value', (snapshot:any) => {
            let res = snapshot?.val();
            console.log(res)
            this.setState({
                contact:res
            })
        });
    }




    render() {

        return <div id={'contact-page'}>

            {/* SEO */}
            <Helmet>
                {this.state?.contact?.seo?.meta_titolo?._value ?
                    <title>{this.state?.contact?.seo?.meta_titolo?._value +" - "+ process.env.REACT_APP_TITLE}</title> :
                    <title>{process.env.REACT_APP_TITLE}</title>}
                <meta name='description' content={this.state.contact?.seo?.meta_descrizione?._value} />
                <meta name='keywords' content={this.state?.contact?.seo?.meta_keywords?._value} />
            </Helmet>

            {/*Intro*/}
            <div data-aos="fade-in" data-aos-once="true" className={'intro-div padded'}>
                <div className={'intro-test-p '}>
                    <p className={'font-48-600'}
                       dangerouslySetInnerHTML={{__html: this.state.contact?.titolo?._value}}>
                    </p>
                    <p className={'font-14 subtitle'}
                       dangerouslySetInnerHTML={{__html: this.state.contact?.descrizione?._value}}>

                    </p>
                </div>

            </div>


                <div className={'columns-contact-container padded'}>

                    <div id={"column1"}  data-aos="fade-in" data-aos-once="true">
                        <div>
                        <p className={'font-14-600 accent-text'}>{this.state.contact?.colonna_1?.titolo_colonna?._value}</p>

                        <p className={'font-14'}
                           dangerouslySetInnerHTML={{__html:this.state.contact?.colonna_1?.descrizione_colonna?._value}}></p>
                        </div>
                    </div>

                    <div id={"column2"}  data-aos="fade-in" data-aos-once="true" data-aos-delay={150}>
                        <div>
                        <p className={'font-14-600 accent-text'}>{this.state.contact?.colonna_2?.titolo_colonna?._value}</p>

                        <p className={'font-14'}
                           dangerouslySetInnerHTML={{__html:this.state.contact?.colonna_2?.descrizione_colonna?._value}}></p>
                        </div>
                    </div>

                    <div id={"column3"}  data-aos="fade-in" data-aos-once="true" data-aos-delay={300}>
                        <div>
                        <p className={'font-14-600 accent-text'}>{this.state.contact?.colonna_3?.titolo_colonna?._value}</p>

                        <p className={'font-14'}
                           dangerouslySetInnerHTML={{__html:this.state.contact?.colonna_3?.descrizione_colonna?._value}}></p>
                        </div>
                    </div>

                </div>

                {this.state.contact?.mappa_incorporata_da_google_maps?.['_value'] ?
                    <div  className={'map-frame padded'}
                          data-aos="fade"
                          data-aos-once="true"
                          dangerouslySetInnerHTML={{
                              __html: this.state.contact?.mappa_incorporata_da_google_maps?.['_value'] ?
                                  DOMPurify.sanitize(this.state.contact?.mappa_incorporata_da_google_maps?.['_value'], {
                                      ALLOWED_TAGS: ["iframe"],
                                      ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling']
                                  }) : ''
                          }}>
                    </div>
                    :
                    ''
                }



        </div>

    }

}

Contact.contextType = AppContext;
export default withNavigate(WithDialog(Contact));

