import React from "react";
import './News-List.css';
import {DialogPropTypes} from "../../ui/dialog/DialogContext";
import {AppContext, AppContextType} from "../../AppContext";
import {withNavigate} from "../../WithNavigate";
import WithDialog from "../../ui/dialog/WithDialog";
import firebase from "../../firebase";
import NewsCell from "../news-cell/News-Cell";
// @ts-ignore
import {Helmet} from "react-helmet";

interface NewsListProps extends DialogPropTypes {

}

class NewsList extends React.Component<NewsListProps, any> {

    constructor(props: any) {
        super(props);

        this.state = {}
    }


    async componentDidMount() {
        const ctx = (this.context as AppContextType).appContext;

        const dbRef = firebase.database().ref(ctx.basePath + '/pagine/news');
        dbRef.on('value', snapshot => {
            let res = snapshot?.val();

            const categories:any = [].concat(Object.values(res.categorie_news?._list)??[]);

            let firstCategoryId = 1;
            if (categories) {

                firstCategoryId = categories[0]?._id || 1;

            }
            this.setState({
                news: snapshot.val(),
                currentCategoryId: firstCategoryId}, () =>{
                this.getNewsInCategory(firstCategoryId)
            })
        });
    }

    setCurrentCategoryId(id: number) {
        this.setState({
            currentCategoryId: +id,
        }, ()=>{
            this.getNewsInCategory(+id);
        });

    }

    getNewsInCategory(id: number) {
        this.setState({
            newsInCategory: []
        }, () =>{
            let news = (this.state?.news?.categorie_news?._list??[])?.find((item:any) => item?._id == id && item?._id!=0)?.news_collegate?._list?.filter((item:any) => item?._id!=0);

            news = [].concat(Object.values(news)??[])?.sort((a:any,b:any)=> a._order >= b._order ? 1 : -1);

            this.setState({newsInCategory: news});
        })


    }


    render() {

        return <div id={'news-page'} >

            {/* SEO */}
            <Helmet>
                {this.state?.news?.seo?.meta_titolo?._value ?
                    <title>{this.state?.news?.seo?.meta_titolo?._value +" - "+ process.env.REACT_APP_TITLE}</title> :
                    <title>{process.env.REACT_APP_TITLE}</title>}
                <meta name='description' content={this.state.news?.seo?.meta_descrizione?._value} />
                <meta name='keywords' content={this.state?.news?.seo?.meta_keywords?._value} />
            </Helmet>

            {/*Intro*/}
            <div data-aos="fade-in" data-aos-once="true" className={'intro-div padded'}>
                <div className={'intro-test-p'}>
                    <p className={'font-48-600'}
                       dangerouslySetInnerHTML={{__html: this.state.news?.titolo_news?._value}}>

                    </p>
                    <p className={'font-14 subtitle'}
                       dangerouslySetInnerHTML={{__html: this.state.news?.sottotitolo_news?._value}}>

                    </p>
                </div>


            </div>

            {/*Griglia sofware con categorie*/}
            <div id={'software-grid'} className={'padded'}>
                {
                    Object.keys(this.state.news?.categorie_news?._list||{})?.
                    filter((key:string)=> key!="0")?.length>1?
                        <div id={'software-categories'}>
                            {
                                Object.keys(this.state.news?.categorie_news?._list||{})?.
                                filter((key:string)=> key!="0")?.
                                map((key:string)=>this.state.news?.categorie_news?._list[key])?.sort((item1, item2)=> item1?._order > item2?.order? -1:1).
                                map((categoria:any, index:number) => {
                                    return <div key={'sw' + index} className={'category-chip font-14 ' +((categoria?._id) === this.state?.currentCategoryId ? 'selected-category' : '')}
                                                onClick={() => this.setCurrentCategoryId(+(categoria?._id))}>
                                        {categoria?.titolo_categoria?._value}
                                    </div>
                                })
                            }
                        </div>
                        : null
                }

                <div className={'software-list-container-div'}>
                    {
                        this.state?.newsInCategory?.map((news: any, index: number) => {
                            return (
                                <NewsCell
                                    key={'sc' +news?._id}
                                    index={index}
                                              newsKey={news?._linkedId}>

                                </NewsCell>
                            );
                        })}
                </div>
            </div>

        </div>

    }

}

NewsList.contextType = AppContext;
export default withNavigate(WithDialog(NewsList));

