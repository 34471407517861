import React from "react";
import './Software-Detail.css';
import {DialogPropTypes} from "../../ui/dialog/DialogContext";
import {AppContext, AppContextType} from "../../AppContext";
import {withNavigate} from "../../WithNavigate";
import WithDialog from "../../ui/dialog/WithDialog";
import firebase from "../../firebase";
import BackBar from "../../ui/back-bar/BackBar";
import ScrollContainer from "react-indiana-drag-scroll";
import SoftwareCell from "../software-cell/Software-Cell";
import NewsCell from "../../news/news-cell/News-Cell";
import {ImagesSlider} from "../../ui/images-slider/ImagesSlider";
import {RouteProps} from "react-router";
// @ts-ignore
import {Helmet} from "react-helmet";

interface SoftwareDetailProp extends DialogPropTypes {
    navigate: any;
    params:any;
    key:any;
}



class SoftwareDetail extends React.Component<SoftwareDetailProp & RouteProps, any>  {

    plainDescriptionText = '';

    constructor(props: any) {
        super(props);

        this.state = {}
    }


    async componentDidMount() {
        const ctx = (this.context as AppContextType).appContext;
        const softwareKey = this.props.params.key;

        const dbRef = firebase.database().ref(ctx.basePath + '/software/_list/' + softwareKey);
        dbRef.on('value', snapshot => {
            let software = snapshot.val();
            this.plainDescriptionText = new DOMParser()
                .parseFromString(software?.sottotitolo?._value, "text/html")
                .documentElement.textContent as string;
            this.setState({software: software})
        });

        const dbRefLabels = firebase.database().ref(ctx.basePath + '/impostazioni/etichette/software');
        dbRefLabels.on('value', snapshot => {
            this.setState({labels: snapshot.val()})
        });

    }

    componentDidUpdate(prevProps:any) {

         if(prevProps.params?.key !== this.props?.params?.key){
            this.componentDidMount()
        }
    }



    render() {

        return <div id={'software-page'}>

            {/* SEO */}
            <Helmet>
                {this.state.software?._description?._value ?
                    <title>{this.state.software?._description?._value +" - "+ process.env.REACT_APP_TITLE}</title> :
                    <title>{process.env.REACT_APP_TITLE}</title>}
                <meta name='description' content={this.plainDescriptionText as string} />
                <meta name='keywords' content={this.state.software?.tags?._value} />
            </Helmet>

            <BackBar buttons={this.state.software?.pulsanti?._list}></BackBar>

            <div className={'software-detail-div padded'}  data-aos="fade-in" data-aos-once="true">


                    <div className={'info-software-div'}>

                        <p className={'font-40-600 title'}>{this.state.software?._description?._value}</p>
                        <p id={'sw-description'} className={'font-14 subtitle'} dangerouslySetInnerHTML=
                            {{__html:this.state.software?.sottotitolo?._value}}></p>
                        <p className={'tags font-14 subtitle'} dangerouslySetInnerHTML=
                            {{__html:this.state.software?.tags?._value}}></p>

                        {/*Image*/}
                        <div id={'software-media-container'}>
                            <div id={'software-media'} data-aos="fade-up" data-aos-once="true"
                                 className={'img-container-home-div img-container-main-home-div kreosoft-pointer'}>
                                {
                                    this.state.software?.gallery?._list && Object.keys(this.state.software?.gallery?._list).length > 0 ?
                                        <ImagesSlider autoplay={true} images={Object.keys(this.state.software?.gallery?._list).filter((s:string)=> s!="0").map(key=> this.state.software?.gallery?._list[key])}>
                                        </ImagesSlider> : ''
                                }
                            </div>
                        </div>



                        {
                            this.state.software?.sezioni?._list? Object.keys(this.state.software?.sezioni?._list)
                            .filter((s:string)=> s!="0").sort((a,b) => this.state?.software?.sezioni?._list[a]?._order >this.state?.software?.sezioni?._list[b]?._order? 1: -1).map(
                                s=>
                                    <div key={'s_'+s} className={'software-section padded'}>

                                        <p className={'section-title font-24-600'}  dangerouslySetInnerHTML=
                                            {{__html:this.state.software.sezioni._list[s]?.titolo_sezione?._value}}></p>
                                        <p key={'sp_'+s} className={'section-description font-14'}  dangerouslySetInnerHTML=
                                            {{__html:this.state.software.sezioni._list[s]?.descrizione_sezione?._value}}>
                                        </p>


                                        {
                                            this.state.software.sezioni?._list[s]?.gallery_sezione && this.state.software.sezioni?._list[s]?.gallery_sezione?._list && Object.keys(this.state.software.sezioni?._list[s]?.gallery_sezione?._list)?.length>1?
                                                <div className={'cursor-grab'}>
                                                <ScrollContainer className="gallery-detail">

                                                    {
                                                        Object.keys(this.state.software?.sezioni?._list[s]?.gallery_sezione?._list||[])?.
                                                        filter((key:string)=> key!="0")?.
                                                        map((key:string)=>this.state.software?.sezioni?._list[s]?.gallery_sezione?._list[key])?.sort((item1, item2)=> item1?._order > item2?.order? -1:1).
                                                        map((immagine:any, index:number) => {
                                                            return <img data-aos="fade-in" data-aos-once="true" data-aos-delay={50*(index??0)} key={'phco'+ index} className="gallery-item-detail background" src={immagine?.immagine?._value} ></img>
                                                        })
                                                    }

                                                </ScrollContainer>
                                                </div>
                                                : null
                                        }


                                    </div>
                            ) : ""
                        }

                    </div>
            </div>


            {/* Software correlati*/}
            {
                this.state?.software?.software_correlati?._list && Object.keys(this.state.software.software_correlati._list) &&
                Object.keys(this.state.software.software_correlati._list)?.length>1?
                    <div id={'related-software'} className={'padded'}>
                        <p className={'font-40-600 title-label'}>{this.state.labels?.software_correlati_titolo?._value}</p>

                        <p className={'font-14 subtitle-label'}
                           dangerouslySetInnerHTML={{__html:this.state.labels?.software_correlati_descrizione?._value}}></p>

                        <div className={'software-list-container-div'}>
                            {
                                this.state?.software?.software_correlati?._list ?
                                    Object.keys(this.state.software.software_correlati._list)
                                        .filter(swK=>swK!="0")
                                        .sort((key1, key2)=> this.state.software?.software_correlati?._list[key1]?._order > this.state.software?.software_correlati?._list[key2]?.order? -1:1)
                                        .map((swK: any, index: number) => {
                                        let sw = this.state?.software?.software_correlati?._list[swK];
                                        return (
                                            <SoftwareCell
                                                key={'sc' + sw?._linkedId}
                                                index={index}
                                                softwareKey={sw?._linkedId}>
                                            </SoftwareCell>
                                        );
                                    }) : "" }
                        </div>
                    </div>:
                    null
            }

            {/* News correlate*/}
            {
                this.state?.software?.software_correlati?._list && Object.keys(this.state.software.software_correlati._list) &&
                Object.keys(this.state.software.software_correlati._list)?.length>1?
                    <div id={'related-news'} className={'news-background padded'}>

                        <p className={'font-40-600 title-label'}>{this.state.labels?.news_correlati_titolo?._value}</p>

                        <p className={'font-14 subtitle-label'}
                           dangerouslySetInnerHTML={{__html:this.state.labels?.news_correlati_descrizione?._value}}></p>

                        <div className={'software-list-container-div'}>
                            {
                                this.state?.software?.news_correlate?._list ?
                                    Object.keys(this.state.software.news_correlate._list)
                                        .filter(nK=>nK!="0")
                                        .sort((key1, key2)=> this.state.software?.news_correlate?._list[key1]?._order > this.state.software?.news_correlate?._list[key2]?.order? -1:1)
                                        .map((nK: any, index: number) => {
                                        let news = this.state?.software?.news_correlate?._list[nK];
                                        return (
                                            <NewsCell
                                                key={'new_' +news?._id}
                                                index={index}
                                                newsKey={news?._linkedId}>
                                            </NewsCell>
                                        );
                                    }) : "" }
                        </div>

                    </div> :
                    null
            }


        </div>

    }

}

SoftwareDetail.contextType = AppContext;
export default withNavigate(WithDialog(SoftwareDetail));

